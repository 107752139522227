<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 511.99 511.99"
    style="enable-background:new 0 0 511.99 511.99;"
    xml:space="preserve"
  >
    <rect y="215.704" style="fill:#82DCC7;" width="440.333" height="215.251" />
    <g>
      <path
        style="fill:#74CBB4;"
        d="M46.438,430.955H0v-46.438C25.645,384.518,46.438,405.3,46.438,430.955z"
      />
      <path
        style="fill:#74CBB4;"
        d="M0,215.703h46.438c0,25.645-20.793,46.438-46.438,46.438V215.703z"
      />
      <path
        style="fill:#74CBB4;"
        d="M440.333,215.702v46.439c-25.653,0-46.427-20.798-46.427-46.439H440.333z"
      />
      <path
        style="fill:#74CBB4;"
        d="M440.338,384.518v46.438H393.9C393.901,405.3,414.683,384.518,440.338,384.518z"
      />
      <ellipse
        style="fill:#74CBB4;"
        cx="220.166"
        cy="323.33"
        rx="58.457"
        ry="73.759"
      />
      <rect
        x="307.586"
        y="303.655"
        style="fill:#74CBB4;"
        width="105.983"
        height="16.39"
      />
      <rect
        x="22.399"
        y="303.655"
        style="fill:#74CBB4;"
        width="105.983"
        height="16.39"
      />
      <rect
        x="348.01"
        y="334.526"
        style="fill:#74CBB4;"
        width="65.558"
        height="16.39"
      />
      <rect
        x="22.399"
        y="334.526"
        style="fill:#74CBB4;"
        width="65.558"
        height="16.39"
      />
    </g>
    <polygon
      style="fill:#82DCC7;"
      points="211.975,299.711 228.365,299.711 254.593,299.711 254.593,283.322 228.365,283.322 
	228.365,265.145 211.975,265.145 211.975,283.322 185.746,283.322 185.746,331.521 238.203,331.521 238.203,346.94 228.365,346.94 
	211.975,346.94 185.746,346.94 185.746,363.33 211.975,363.33 211.975,381.507 228.365,381.507 228.365,363.33 254.593,363.33 
	254.593,315.131 202.135,315.131 202.135,299.711 "
    />
    <path
      style="fill:#A3A3A3;"
      d="M485.946,202.029c-5.004,0-9.058-4.056-9.058-9.058v-77.88c0-8.789-7.15-15.939-15.939-15.939
	h-53.692c-8.789,0-15.94,7.15-15.94,15.939v77.88c0,5.003-4.055,9.058-9.058,9.058s-9.058-4.056-9.058-9.058v-77.88
	c0-18.779,15.277-34.056,34.057-34.056h53.692c18.779,0,34.056,15.277,34.056,34.056v77.88
	C495.004,197.973,490.948,202.029,485.946,202.029z"
    />
    <rect
      x="338.07"
      y="215.704"
      style="fill:#74CBB4;"
      width="102.263"
      height="145.151"
    />
    <rect
      x="356.199"
      y="186.923"
      style="fill:#FED159;"
      width="155.792"
      height="155.792"
    />
    <rect
      x="356.199"
      y="186.923"
      style="fill:#F6C454;"
      width="14.133"
      height="155.792"
    />
    <rect
      x="470.938"
      y="186.923"
      style="fill:#FFE4A9;"
      width="14.133"
      height="155.792"
    />
    <path
      style="fill:#707070;"
      d="M454.384,258.59c0-11.201-9.08-20.281-20.282-20.281c-11.201,0-20.281,9.08-20.281,20.281
	c0,7.941,4.575,14.798,11.223,18.128v15.067c0,5.003,4.055,9.058,9.058,9.058s9.058-4.056,9.058-9.058v-15.066
	C449.809,273.388,454.384,266.531,454.384,258.59z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
